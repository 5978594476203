export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "ID", prop: "id" },
    { label: "所属上级ID", prop: "parent_id", slots: "parent_id", width: 100 },
    { label: "账号等级", prop: "grade", slots: "grade", width: 120 },

    { label: "分配门店", prop: "store_id", slots: "store_id", width: 100 },
    { label: "账号", prop: "sup_username" },
    { label: "密码", prop: "sup_password" },
    // { label: "手机号", prop: "supplier_phone" },
    {
      label: "登录异常(超过3次冻结)",
      prop: "error",
      slots: "error",
      width: 100,
    },
    { label: "绑定微信openid", prop: "openid", slots: "openid", width: 150 },
    // {
    //   label: "微信绑定",
    //   prop: "saoma",
    //   slots: "saoma",
    //   width: 100,
    // },
    { label: "备注", prop: "message" },
    { label: "到期时间", prop: "vip_date", width: 180 },
    {
      label: "创建时间",
      prop: "create_time",
      width: 180,
    },
    {
      label: "操作",
      prop: "operation",
      width: 200,
      slots: "operation",
      fixed: "right",
    },
  ];
}
