<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- 封装selectForm筛选 -->
    <el-button type="primary" size="small" v-waves @click="handleAdd"
      >新增</el-button
    >
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="parent_id" slot-scope="{ row }">
          <p v-if="!row.parent_id">
            —
          </p>
          <el-tag effect="dark" size="mini" v-else>
            所属上级{{ row.parent_id }}
          </el-tag>
        </template>
        <template slot="store_id" slot-scope="{ row }">
          <p v-if="!row.store_id">
            —
          </p>
          <el-tag type="success" effect="dark" size="mini" v-else>
            <!-- {{ row.store_id }} -->
            <StoreToString :store_id="row.store_id" />
          </el-tag>
        </template>

        <!-- <template slot="grade" slot-scope="{ row }">
          <el-tag
            v-if="row.grade === 1"
            type="success"
            effect="dark"
            size="mini"
          >
            单门店(1888)
          </el-tag>
          <el-tag
            v-else-if="row.grade === 2"
            type="success"
            effect="dark"
            size="mini"
          >
            2-5家门店(3688)
          </el-tag>
          <el-tag
            v-else-if="row.grade === 3"
            type="success"
            effect="dark"
            size="mini"
          >
            6-10家门店(6688)
          </el-tag>
          <el-tag
            v-else-if="row.grade === 4"
            type="danger"
            effect="dark"
            size="mini"
          >
            超级管理员
          </el-tag>
          <el-tag v-else effect="dark" size="mini">
            子账号
          </el-tag>
        </template> -->
        <template slot="grade" slot-scope="{ row }">
          <el-tag
            type="danger"
            effect="dark"
            size="mini"
            v-if="row.grade === 0"
          >
            已过期
          </el-tag>
          <el-tag type="success" effect="dark" size="mini" v-else>
            {{
              row.grade === 1
                ? "1888版本(门店点餐)"
                : row.grade === 2
                ? "3688版本(门店点餐+微商城+积分商城)"
                : row.grade === 3
                ? "超级管理员"
                : row.grade === 4
                ? "子账号"
                : "-"
            }}
          </el-tag>
        </template>
        <template slot="error" slot-scope="{ row }">
          <el-tag type="success" effect="dark" size="mini" v-if="!row.error">
            无异常
          </el-tag>
          <el-tag type="danger" effect="dark" size="mini" v-else>
            {{ row.error }}次
          </el-tag>
        </template>
        <template slot="openid" slot-scope="{ row }">
          <el-tag type="success" effect="dark" size="mini" v-if="row.openid">
            已绑定
          </el-tag>
          <el-tag type="danger" effect="dark" size="mini" v-else>
            未绑定
          </el-tag>
        </template>
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button size="mini" type="text" @click="handleRole(row)" primary
              >设置权限</el-button
            >
            <el-button size="mini" type="text" @click="handleEdit(row)" primary
              >修改</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
            <el-button size="mini" type="text" @click="handleRole(row)" primary
              >通知</el-button
            >
          </div>
        </template>
      </ElTable>
    </div>
    <!-- 表单弹窗 -->
    <el-dialog
      :title="dialogName"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="dialogForm" label-width="90px">
        <el-form-item label="账号等级:" required>
          <el-select
            v-model="dialogForm.grade"
            placeholder="请选择"
            style="width: 100%;"
            clearable
          >
            <div v-for="(item, index) in gradeList" :key="index">
              <el-option :label="item.name" :value="item.id"></el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="到期时间" required>
          <el-input clearable v-model="dialogForm.vip_date"></el-input>
        </el-form-item>
        <el-form-item label="分配门店:" required>
          <el-select
            v-model="dialogForm.store_id"
            placeholder="请选择"
            style="width: 100%;"
            clearable
            multiple
            filterable
          >
            <div v-for="(item, index) in storeList" :key="index">
              <el-option
                :label="item.store_name"
                :value="item.store_id + ''"
              ></el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="账号" required>
          <el-input
            clearable
            v-model="dialogForm.sup_username"
            placeholder="格式为11位数手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" required>
          <el-input clearable v-model="dialogForm.sup_password"></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号">
          <el-input clearable v-model="dialogForm.supplier_phone"></el-input>
        </el-form-item> -->
        <el-form-item label="微信绑定">
          <el-input
            clearable
            v-model="dialogForm.openid"
            placeholder="请输入openid"
          ></el-input>
        </el-form-item>

        <el-form-item label="异常登录">
          <el-input
            clearable
            v-model="dialogForm.error"
            placeholder="请输入次数"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            clearable
            v-model="dialogForm.message"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级ID">
          <el-input
            clearable
            v-model="dialogForm.parent_id"
            :disabled="!dialogForm.id"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button class="addBtn" size="small" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 权限弹窗 -->
    <el-dialog
      v-if="roleVisible"
      title="权限设置"
      :visible.sync="roleVisible"
      :close-on-click-modal="false"
    >
      <div style="height: 350px;overflow-y: auto;">
        <el-tree
          ref="tree"
          :data="navData"
          show-checkbox
          node-key="id"
          :default-expanded-keys="[]"
          :default-checked-keys="checkedData"
          :props="defaultProps"
          :check-strictly="true"
        >
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" size="small" @click="roleVisible = false">取 消</el-button>
        <el-button class="addBtn" size="small" type="primary" @click="roleSave"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
import filterQueryFormItems from "./filterQueryFormItems";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
import StoreToString from "@/components/filters/storeToString.vue";
import { selectStore } from "@/services/mall/store";
import { mapState } from "vuex";
import {
  selectAdmin,
  deleteAdmin,
  updateAdmin,
  addAdmin,
  updateRole,
} from "@/services/mall/admin";
export default {
  components: {
    ElTable,
    Percentage,
    FillingStatus,
    KindTab,
    StoreToString,
  },
  // directives: {
  //   waves,
  // },
  created() {
    //根据权限初始化导航
    this.navData = this.$store.state.navList;
    console.log("navData", this.navData);
    this.getListData();
    this.getStoreData();
  },
  data() {
    return {
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
      storeList: [],
      /* 弹窗 */
      dialogName: "",
      dialogVisible: false,
      dialogForm: {},
      gradeList: [
        { id: 0, name: "已过期" },
        { id: 1, name: "1888版本(门店点餐)" },
        { id: 2, name: "3688版本(门店点餐+微商城+积分商城)" },
        { id: 3, name: "超级管理员" },
        { id: 4, name: "子账号" },
      ],
      roleVisible: false,
      navData: [],
      checkedData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  computed: {
    filterQueryFormItems,
    listHeader,
    ...mapState(["userInfo"]),
  },

  watch: {},
  methods: {
    async getListData(listQuery) {
      this.loading = true;
      const { data, code, count } = await selectAdmin(
        listQuery || this.listQuery
      );
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 搜索按钮 */
    async search(listQuery) {
      console.log("listQuery", listQuery);
      this.listQuery = listQuery;
      this.getListData();
    },
    /* 分页 */
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    /* 所以门店数据 */
    async getStoreData() {
      const { data, code } = await selectStore({
        pageNumber: 1,
        pageSize: 100,
      });
      if (code === 200) {
        this.storeList = data;
      }
    },
    /* 新增 */
    handleAdd() {
      this.dialogVisible = true;
      this.dialogName = "创建账号";
      this.dialogForm = {};
    },

    /* 修改 */
    handleEdit(row) {
      console.log(row);
      this.dialogVisible = true;
      this.dialogName = "编辑账号";
      this.dialogForm = JSON.parse(JSON.stringify(row));
      this.dialogForm.store_id = this.dialogForm.store_id.split(",");
    },

    /* 设置权限 */
    handleRole(row) {
      this.roleVisible = true;
      this.dialogForm = JSON.parse(JSON.stringify(row));
      this.checkedData = this.dialogForm.role_id?.split(",");
    },

    /* 权限保存 */
    roleSave() {
      // console.log(this.$refs.tree.getCheckedNodes());
      // console.log(this.$refs.tree.getCheckedKeys());
      // console.log(this.$refs.tree.getHalfCheckedKeys())
      // console.log(this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys()));
      updateRole({
        id: this.dialogForm.id,
        role_id: this.$refs.tree.getCheckedKeys().join(","),
      }).then((res) => {
        if (res.code === 200) {
          this.getListData();
          this.$message({
            type: "success",
            message: "编辑成功!",
          });
          this.roleVisible = false;
        }
      });
    },

    save() {
      //必填字段校验
      if (
        !this.dialogForm.vip_date ||
        !this.dialogForm.store_id ||
        !this.dialogForm.sup_username ||
        !this.dialogForm.sup_password
      ) {
        this.$message.error("请填写必填字段");
        return;
      }
      /* 账号格式校验 */
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.dialogForm.sup_username)) {
        this.$message.error("账号格式错误");
        return;
      }

      if (this.dialogName === "创建账号") {
        addAdmin({
          ...this.dialogForm,
          parent_id: this.userInfo.id,
          store_id: this.dialogForm?.store_id?.join(","),
        }).then((res) => {
          console.log("创建账号", res);
          if (res.code === 200) {
            this.listQuery.pageNumber = 1;
            this.getListData();
            this.$message({
              type: "success",
              message: "创建成功!",
            });
            this.dialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        });
      }
      if (this.dialogName === "编辑账号") {
        updateAdmin({
          ...this.dialogForm,
          store_id: this.dialogForm?.store_id?.join(","),
        }).then((res) => {
          console.log("编辑账号", res);
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.dialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        });
      }
    },

    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm("此操作将永久删除该账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row.id);
          deleteAdmin({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}
</style>
